function changeLanguage(language) {
  const elements = document.querySelectorAll('[class*="lang-"]');

  elements.forEach(element => {
    const languages = element.className.split(' ');
    languages.forEach(lang => {
      if (lang.includes('lang-')) {
        element.style.display = lang === `lang-${language}` ? 'block' : 'none';
      }
    });
  });
}
